<template>
  <gf-content overflow title="Warehouse Detail" subtitle="Modify your warehouse information" v-loading="loading">
    <template #toolbar>
      <gf-button type="primary" @click="save">Save Changes</gf-button>
    </template>
    <gf-form :width="1200">
      <el-form size="medium" ref="warehouse" :model="warehouse" :rules="warehouseRule">
        <el-row :gutter="30">
          <el-col :span="8">
            <el-form-item class="mb-0">
              <span class="font-weight-bold">Detail</span>
            </el-form-item>
            <el-form-item label="Name" prop="name">
              <el-input v-model="warehouse.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="mb-0">
              <span class="font-weight-bold">Address</span>
            </el-form-item>
            <el-form-item label="Address Line 1" prop="address_line_1">
              <el-input v-model="warehouse.address_line_1"></el-input>
            </el-form-item>
            <el-form-item label="Address Line 2" prop="address_line_2">
              <el-input v-model="warehouse.address_line_2"></el-input>
            </el-form-item>
            <el-form-item label="City" prop="city">
              <el-input v-model="warehouse.city"></el-input>
            </el-form-item>
            <el-form-item label="Province" prop="state">
              <el-input v-model="warehouse.state"></el-input>
            </el-form-item>
            <el-form-item label="Zip Code" prop="zip_code">
              <el-input v-model="warehouse.zip_code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="mb-0">
              <span class="font-weight-bold">Contacts</span>
            </el-form-item>
            <el-form-item label="Email Address" prop="email">
              <el-input v-model="warehouse.email"></el-input>
            </el-form-item>
            <el-form-item label="Contact No" prop="contact_no">
              <el-input v-model="warehouse.contact_no"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </gf-form>
  </gf-content>
</template>

<script>
import WarehouseService from '@/services/v1/Warehouse'

export default {
  data () {
    return {
      loading: false,
      warehouse: {
        name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        zip_code: null,
        email: null,
        contact_no: null
      },
      warehouseRule: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ],
        address_line_1: [
          { required: true, message: 'Address Line 1 is require', trigger: 'blur' }
        ],
        address_line_2: [
          { required: true, message: 'Address Line 2 is require', trigger: 'blur' }
        ],
        city: [
          { required: true, message: 'City is require', trigger: 'blur' }
        ],
        state: [
          { required: true, message: 'Province is require', trigger: 'blur' }
        ],
        zip_code: [
          { required: true, message: 'Zip Code is require', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email is require', trigger: 'blur' }
        ],
        contact_no: [
          { required: true, message: 'Contact No is require', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    save () {
      this.$refs.warehouse.validate()
        .then(async () => {
          try {
            this.loading = true
            const whService = new WarehouseService(1)
            await whService.update(this.warehouse)
            this.$message.success('Warehouse successfuly updated!')

            this.getWarehouse()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getWarehouse () {
      try {
        this.loading = true
        const whService = new WarehouseService(1)
        const result = await whService.get()
        this.warehouse = result.data
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 3)

    await this.getWarehouse()
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Settings' },
      { title: 'Company Details' },
      { title: 'Warehouse' }
    ])
  }
}
</script>
